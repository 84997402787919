<template>
  <div>
    <page-header :title="title" :menu="menu" />
    <v-card elevation="0">
      <v-card-text>
        <v-container>
          <br />
          <br />
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-row>
                <v-col cols="12" md="4" class="mx-2">
                  <v-select
                    @change="checkType"
                    outlined
                    v-model="opportunity.productType"
                    label="Tipo"
                    :items="['PRODUTO', 'SERVICO']"
                    required
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-end align-end">
                <v-col cols="6" sm="4" class="mt-n8">
                  <span>Total R$</span>
                  <money
                    class="money"
                    v-model="opportunity.amount"
                    v-bind="money"
                  ></money>
                </v-col>
              </v-row>
              <v-col
                cols="12"
                :md="opportunity.productType === 'PRODUTO' ? '8' : '12'"
              >
                <v-text-field
                  outlined
                  v-model="opportunity.title"
                  label="Nome"
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  outlined
                  v-model="opportunity.model"
                  label="Modelo"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="mt-n8" md="4">
                <v-select
                  outlined
                  v-model="opportunity.timeService"
                  label="Tempo Execução Minutos"
                  :items="serviceTimes"
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="4" :class="visibleFields ? 'mt-n8' : ''">
                <v-autocomplete
                  outlined
                  :items="categories"
                  v-model="opportunity.categoryId"
                  label="Selecionae uma Categoria*"
                  item-text="title"
                  item-value="id"
                  :loading="loading"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                ></v-autocomplete>
              </v-col>

              <v-col cols="6" md="2" class="mt-n8">
                <v-text-field
                  outlined
                  v-model="opportunity.stock"
                  label="Estoque"
                  type="number"
                ></v-text-field>
              </v-col>

              <v-col cols="6" md="3" class="mt-n8">
                <v-text-field
                  outlined
                  v-model="opportunity.manufacturer"
                  label="Fabricante"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="3" class="mt-n8">
                <v-text-field
                  outlined
                  v-model="opportunity.brand"
                  label="Marca"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="6" md="3" class="mt-n8">
                <v-select
                  outlined
                  v-model="opportunity.unity"
                  label="Unidade"
                  :items="['UN', 'MT', 'LT', 'KG']"
                  required
                ></v-select>
              </v-col>

              <v-col cols="12" md="4" class="mt-n8">
                <v-text-field
                  outlined
                  v-model="opportunity.ncm"
                  label="NCM"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="5" class="mt-n8">
                <v-text-field
                  outlined
                  v-model="opportunity.barCode"
                  label="Código de Barras"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="8">
                <v-textarea
                  outlined
                  label="Descrição Completa"
                  v-model="opportunity.description"
                  rows="7"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-card-actions class="d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text to="/opportunities">
                Cancelar
              </v-btn>
              <v-btn
                dark
                :disabled="!valid"
                :loading="loading"
                width="200px"
                color="#2ca01c"
                @click="validate()"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>

    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { Money } from "v-money";
import PageHeader from "@/components/core/PageHeader.vue";
export default Vue.extend({
  title: "OppoirtunityForm",
  components: { Money, PageHeader },
  data: () => ({
    valid: true,
    title: "Registro de Oportunidade",
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Produtos",
        disabled: false,
        href: "/base/opportunities",
      },
      {
        text: "Cadastrar Produto",
        disabled: true,
        href: "/base/opportunities",
      },
    ],
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },
    opportunities: [],
    categories: [],
    opportunityId: null,
    opportunity: {
      title: "",
      description: "",
      stock: 2,
      categoryId: "",
      amount: 0.0,
      imageUrl: "",
      productType: "PRODUTO",

      ncm: "",
      brand: "",
      bardCode: "",
      manufacturer: "",
      model: "",
      unity: "UN",
      stockControl: true,
    },
    search: "",
    loading: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    tributeId: "",
  }),
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) this.registerOrUpdate();
    },
    async getItem() {
      const opportunityId = this.$route.params.id;
      if (opportunityId) {
        this.title = "Editar Produto";
        this.menu[2].text = "Editar Produto";
        await http.get(`opportunities/${opportunityId}`).then(async (res) => {
          const { data } = await res;
          this.opportunity = data;
          this.opportunityId = data.id;
          this.urlPreview = data.imageUrl;
        });
      }
    },
    getCategories() {
      http.get("opportunity/categories").then((res) => {
        this.categories = res.data;
      });
    },

    async registerOrUpdate() {
      this.loading = true;
      if (this.opportunityId) {
        await http
          .put(`opportunities/${this.opportunityId}`, this.opportunity)
          .then(
            () => {
              this.snackbar.text = "Produto atualizado com sucesso";
              this.snackbar.color = "success";
              this.snackbar.opened = true;
              this.loading = false;
              this.$router.push("/opportunities");
            },
            (err) => {
              const { response } = err;
              const message =
                typeof response?.data === "string"
                  ? response?.data
                  : response?.data?.title;
              this.snackbar.text = "Erro ao atualizar o produto: " + message;
              this.snackbar.color = "red";
              this.snackbar.opened = true;
              this.loading = false;
            }
          );
      } else {
        await http.post("opportunities", this.opportunity).then(
          () => {
            this.snackbar.text = "Produto cadastrado com sucesso";
            this.snackbar.color = "success";
            this.snackbar.opened = true;
            this.loading = false;
            this.$router.push("/base/opportunities");
          },
          (err) => {
            const { response } = err;
            const message =
              typeof response?.data === "string"
                ? response?.data
                : response?.data?.title;
            this.snackbar.text = "Erro ao cadastrar o produto: " + message;
            this.snackbar.color = "red";
            this.snackbar.opened = true;
            this.loading = false;
          }
        );
      }
      this.loading = false;
    },
  },
  async mounted() {
    await this.getCategories();
    await this.getItem();
  },
});
</script>
<style>
.money {
  background-color: rgb(0, 126, 0);
  height: 55px;
  padding: 15px;
  font-weight: bolder;
  font-size: 16px;
  width: 100%;
  color: aliceblue;
  border-radius: 5px;
}
.money:focus {
  outline: none !important;
  background-color: rgb(7, 85, 7);
}
</style>
